<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card text class="mb-7">
                    <v-card-text class="py-4 px-6">
                        <v-row>
                            <v-col cols="12" align="right" class="d-flex justify-space-between tw-flex-col md:tw-flex-row">
                                <div class="text-center tw-flex-row tw-flex">
                                    <h1 class="mb-0 text-h5 md:tw-mr-4">{{exam.name}}</h1>
                                </div>
                                <div class="tw-flex justify-space-between tw-flex-row md:mt-2">
                                    <v-menu rounded offset-y>
                                        <template v-slot:activator="{ attrs, on }">
                                            <v-btn color="gray" v-bind="attrs" v-on="on" icon>
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="importExam()">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-text-box-plus-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Adicionar às minhas avaliações</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="dialogDelete=true">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Excluir modelo</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <base-card class="mt-5" heading="Questões">
                  <v-row>
                    <v-col class='d-flex justify-center align-center my-4'>
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      :value="30"
                      indeterminate
                      color="primary"
                      v-if="loadingQuestions"
                    ></v-progress-circular>
            </v-col>
          </v-row>
                  <tabs-question
                    :toolbar="false"
                    :questions="questions"
                    :elevation="0"
                    class="mb-4 mt-2"
                    >
                      <v-pagination
                        v-if="questions.length"
                        v-model="pagination.current_page"
                        depressed
                        :light="true"
                        class="my-4"
                        :length="countPagination"
                      />
                    </tabs-question>
                </base-card>
            </v-col>
       </v-row>
        <dialog-delete
          :dialog="dialogDelete"
          header-message="Deseja excluir esse modelo de avaliação?"
          body-message="Essa ação não pode ser desfeita depois da confirmação."
          deny-message="Cancelar"
          confirm-message="Confirmar exclusão"
          :delete-endpoint="`/organizations/${organizationId}/exams-database/${$route.params.id}`"
          @deleteSuccess="deleteSuccess()"
          @closeModal="closeModal()" />
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import TabsQuestion from '../../components/list/TabsQuestion.vue'
import DialogDelete from '../../components/dialog/DialogDelete.vue'

export default {
  components: { TabsQuestion, DialogDelete },
  data: () => ({
    exam: {},
    questions: [],
    loadingQuestions: true,
    dialogDelete: false,
    organizationId: '',
    examDatabaseId: '',
    pagination: {
      current_page: 1,
      per_page: 10,
      prev_page_url: false,
      next_page_url: false,
      total: 10
    }
  }),
  computed: {
    countPagination () {
      return Math.ceil(this.pagination.total / this.pagination.per_page) || 0
    }
  },
  watch: {
    'pagination.current_page' (value) {
      this.getExamDatabase(value)
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    getExamDatabase (page = 1) {
      this.setLoading(true)
      this.$axios.get(`/organizations/${this.organizationId}/exams-database/${this.examDatabaseId}?with_questions=1&with_wordings=1&page=${page}`)
        .then(response => {
          this.questions = response.data.data.question
          this.exam = {
            name: response.data.data.name
          }
          this.pagination.total = response.data.data.questions_count

          this.loadingQuestions = false
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    importExam () {
      const payload = { exam: { id: this.examDatabaseId } }
      this.setLoading(true)
      this.$axios.post(`organizations/${this.organizationId}/exams/import`, payload)
        .then(() => {
          this.$router.push({ path: '/exam/list', replace: true })
        })
        .finally(() => this.setLoading(false))
    },
    deleteSuccess () {
      this.dialogDelete = false
      this.$router.push({
        path: '/exam/list',
        replace: true
      })
      this.$store.dispatch('alert', {
        msg: 'Modelo de avaliação excluído com sucesso.'
      })
    },
    closeModal () {
      this.dialogDelete = false
    }
  },
  mounted () {
    this.examDatabaseId = this.$route.params.id
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getExamDatabase()
  }

}
</script>
